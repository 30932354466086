import React from "react"
import Button from "./Button"
import { Github } from "./icons/Github"
import { LinkedIn } from "./icons/LinkedIn"

const About = () => {
  return (
    <div
      id="about"
      className="bg-white w-full px-6 pt-24 pb-6 rounded-tr-5xl border-t border-theme_border lg:w-1/2 lg:border-none"
    >
      <h1 className="text-3xl font-black">About Myself</h1>
      <p className="mt-4">
        I'm a Full-stack developer with 4+ years of experience building web
        applications for startups. I love marketing & early-stage companies
        where we discuss ideas and bring it to reality together.
      </p>
      <h1 className="text-theme mt-4">Lets connect</h1>

      <div className="flex my-4">
        <a href="https://www.linkedin.com/in/zehairawan/" target="_blank">
          <LinkedIn className="mr-2" />
        </a>

        <a href="https://github.com/ZehaIrawan" target="_blank">
          <Github className="mr-2" />
        </a>
      </div>

      <a
        href="https://drive.google.com/file/d/1I4WQceoibk9BKyfj1eYBB24b3Lnu_WGe/view?usp=sharing"
        target="_blank"
      >
        <Button
          text="Get My Resume"
          className="hover:bg-theme_button hover:text-white hover:outline-none hover:border-none"
        />
      </a>
    </div>
  )
}

export default About
