import { useForm, ValidationError } from "@formspree/react"
import React from "react"
import Button from './Button'

function ContactForm() {
  const [state, handleSubmit] = useForm("myyokzva")
  if (state.succeeded) {
    alert('Thanks for joining!')
  }
  return (
    <form
      id="contact"
      onSubmit={handleSubmit}
      className="bg-theme rounded-tl-5xl pt-24 pb-12 px-6 text-white bg-[url('../images/contact.svg')] bg-no-repeat bg-cover bg-origin-content"
    >
      <div className="lg:w-2/5 mx-auto flex flex-col justify-center gap-y-6">
        <div className="text-center pt-6">
          <h1 className="font-bold text-3xl">Contact me</h1>
          <p className="mt-4">
            If you have an application you are interested in developing, a
            feature that you need built or a project that needs coding. I’d love
            to help with it
          </p>
        </div>
        <input type="text" name="_gotcha" className="hidden" />
        <input
          id="name"
          type="name"
          name="name"
          placeholder="Yeremias"
          className="w-full py-2 px-2 rounded-md text-black"
        />
        <input
          className="w-full py-2 px-2 rounded-md text-black"
          id="email"
          type="email"
          name="email"
          placeholder="asdasa@gmail.com"
        />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
        <textarea
          id="message"
          name="message"
          placeholder="Write your message here"
          className="w-full mt-6 py-2 px-2 rounded-md h-36 text-black"
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
        <Button
          text="Get In Touch"
          className="w-1/3 mx-auto  hover:bg-theme_button hover:text-white hover:outline-none hover:border-none"
        />
      </div>
    </form>
  )
}

export default ContactForm