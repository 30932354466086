import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid"
import React from "react"
import { Css } from "./icons/Css"
import { ExpressjsIcon } from './icons/ExpressjsIcon'
import { GatsbyIcon } from './icons/GatsbyIcon'
import { HTML } from "./icons/HTML"
import { Javascript } from "./icons/Javascript"
import { NextjsIcon } from './icons/NextjsIcon'
import { ReactIcon } from './icons/ReactIcon'
import { RORIcon } from './icons/RORIcon'
import { Ruby } from "./icons/Ruby"

const TechStack = () => {
  const [isOpen, setIsOpen] = React.useState(0)

  const stack = [
    {
      title: "Languages",
      items: [
        { name: "Javascript", icon: <Javascript className="w-12 h-12" /> },
        {
          name: "Ruby",
          icon: <Ruby className="w-12 h-12" />,
        },
        { name: "CSS", icon: <Css className="w-12 h-12" /> },
        {
          name: "HTML",
          icon: <HTML className="w-12 h-12" />,
        },
      ],
    },
    {
      title: "Frameworks",
      items: [
        { name: "React.js", icon: <ReactIcon className="w-12 h-12" /> },
        {
          name: "Next.js",
          icon: <NextjsIcon className="w-12 h-12" />,
        },
        {
          name: "Express.js",
          icon: <ExpressjsIcon className="w-12 h-12" />,
        },
        {
          name: "Gatsby",
          icon: <GatsbyIcon className="w-12 h-12" />,
        },
        {
          name: "Ruby on Rails",
          icon: <RORIcon className='w-12 h-12 text-red-500'/>
        }
      ]
      },
        { title: "Skills",
        items: [
          {name:"Frontend"},
           {name:"Backend"},
           {name:"Digital Marketing"}
          ]}
  ]

  return (
    <div className="px-6 pt-6 pb-24 lg:w-1/2">
      {stack.map(({ title, items }, index) => {
        return (
          <div key={title} className="mt-2 lg:flex flex-wrap lg:gap-4">
            <div
              onClick={() => setIsOpen(index)}
              className={`basis-full flex lg:w-full justify-between py-2 items-center ${
                isOpen !== index && "border-b-2"
              }`}
            >
              <span className="font-semibold text-lg">{title}</span>
              {isOpen === index ? (
                <ChevronDownIcon className="h-5 w-5 text-icon_color" />
              ) : (
                <ChevronRightIcon className="h-5 w-5 text-icon_color" />
              )}
            </div>
            {isOpen === index &&
              items.map(item => {
                return (
                  <li
                    className="list-none w-full lg:w-fit bg-bg_theme mt-2 py-4 px-4 rounded-md flex gap-x-4 lg:flex-col"
                    key={item.name}
                  >
                    <div>{item.icon}</div>
                    <div> {item.name}</div>
                  </li>
                )
              })}
          </div>
        )
      })}
    </div>
  )
}

export default TechStack
