import React, { useRef, useState } from "react"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/pagination"
import "swiper/css/navigation"

// import required modules
import { Autoplay, Pagination, Navigation, EffectFade } from "swiper/modules"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Recommendation = () => {
  const recommendationList = [
    {
      name: "Marvel Gomulya",
      description:
        "Zeha is an excellent engineer in Unilite. He has spearheaded projects (like our EssayAI) from development to production, gladly learning whatever is required to make it work along the way. He suggests relevant ideas, and has clear passion for programming. He proactively asks details to get the job done. Overall a great engineer and an even better individual!",
      screenshot: "marvel.jpeg",
      title: "Founder & CEO of Unilite",
    },
    {
      name: "Anthony Michel",
      description: `I highly recommend Zeha as a Front End Software Engineer. Since joining Yicom, he has shown outstanding adaptability and skill, tackling complex and legacy code with impressive proficiency. His proactive and curious nature has been a catalyst for his rapid growth within our team.\n
      Zeha excels in collaboration, actively sharing ideas that enhance our processes and product. He has a strong work ethic, consistently delivering high-quality code and showing a keen interest in expanding his expertise into different areas.\n
      In short, Zeha's technical abilities, combined with his collaborative spirit and initiative, make him a valuable asset to any team. He has my fullest endorsement for any future endeavors in his career.`,
      screenshot: "anthony.jpeg",
      title: "Co-founder and CTO of Yicom",
    },
    {
      name: "Edwin Ardiwinata",
      description: `Zeha was an excellent senior software engineer. Zeha had a great relationship with our client and always went above and beyond to deliver his tasks. Zeha had always opened himself to constructive feedback and was willing to pour out his mind to think of things that could be improved in the products he was responsible for.`,
      screenshot: "edwin.jpeg",
      title: "Director of CLADE",
    },
    {
      name: "Didik Mulyadi",
      description: `Zeha is a fast-learner, problem solver, and good person. He has good communication skill and willing to help the others to solve the issue.I learn many things from him, not only about the code but also about the working experience outside our country. I was happy to have him in the team.`,
      screenshot: "didik.jpeg",
      title: "Senior Software Engineer at CLADE",
    },
  ]

  return (
    <div>
      <Swiper
        spaceBetween={50}
        // effect={"fade"}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[EffectFade, Autoplay, Pagination, Navigation]}
      >
        {recommendationList.map((item, index) => (
          <SwiperSlide>
            <div className="px-6 lg:mx-auto lg:w-3/5 flex flex-col justify-end bg-white py-6 rounded-lg border border-gray-200 drop-shadow-lg">
              {/* <p className="text-md">{item.description}</p> */}
              <p className="text-md">
                {item.description.split("\n").map((line, idx) => (
                  <React.Fragment key={idx}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </p>
              <br />

              <div className="flex gap-6 self-end">
                <GatsbyImage
                  image={getImage(`../images/${item.screenshot}`)}
                  src={getImage(`../images/${item.screenshot}`)}
                  alt={item.name}
                  className="w-24 rounded-full h-24"
                />

                <div className="flex flex-col justify-center">
                  <h1 className="text-md">{item.name}</h1>
                  <h1 className="text-sm text-gray-600">{item.title}</h1>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Recommendation
