import React from "react"

export const HTML = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className={className}>
      <path
        fill="#e44f26"
        d="M5.902 27.201L3.655 2 28.345 2 26.095 27.197 15.985 30 5.902 27.201z"
      ></path>
      <path
        fill="#f1662a"
        d="M16 27.858L24.17 25.593 26.092 4.061 16 4.061 16 27.858z"
      ></path>
      <path
        fill="#ebebeb"
        d="M16 13.407L11.91 13.407 11.628 10.242 16 10.242 16 7.151 15.989 7.151 8.25 7.151 8.324 7.981 9.083 16.498 16 16.498 16 13.407z"
      ></path>
      <path
        fill="#ebebeb"
        d="M16 21.434L15.986 21.438 12.544 20.509 12.324 18.044 10.651 18.044 9.221 18.044 9.654 22.896 15.986 24.654 16 24.65 16 21.434z"
      ></path>
      <path
        fill="#fff"
        d="M15.989 13.407L15.989 16.498 19.795 16.498 19.437 20.507 15.989 21.437 15.989 24.653 22.326 22.896 22.372 22.374 23.098 14.237 23.174 13.407 22.341 13.407 15.989 13.407z"
      ></path>
      <path
        fill="#fff"
        d="M15.989 7.151L15.989 9.071 15.989 10.235 15.989 10.242 23.445 10.242 23.445 10.242 23.455 10.242 23.517 9.548 23.658 7.981 23.732 7.151 15.989 7.151z"
      ></path>
    </svg>)
}
