import React from "react"
import { Github } from "./icons/Github"
import { LinkedIn } from "./icons/LinkedIn"

const Section1 = () => {
  return (
    <div
      className={`mt-18 rounded-bl-5xl pt-16 pb-40 bg-white mb-12 border-b border-theme_border lg:max-w-10/12 lg:mx-auto bg-[url('../images/header-bg.svg')] bg-no-repeat bg-cover bg-origin-content h-5/6 drop-shadow-lg`}
    >
      <div className="pt-52 px-6 lg:max-w-4xl mx-auto leading-8">
        <h1 className="text-5xl font-black">
          <span className="block">I’m Zeha Irawan</span>
          <span className="block mt-2">Glad to see you!</span>
        </h1>
        <p className="mt-6 text-xl font-thin">
          I'm a Full-stack developer with 4+ years of experience building web
          applications for startups. I love marketing & early-stage companies
          where we discuss ideas and bring it to reality together.
        </p>

        <div className="mt-2">
          <a href="https://www.linkedin.com/in/zehairawan/" target="_blank">
            <button className="text-violet-500 font-medium text-lg hover:font-bold">
              Let's connect
            </button>
          </a>
          <div className="flex mt-2">
            <a href="https://www.linkedin.com/in/zehairawan/" target="_blank">
              <LinkedIn className="mr-2" />
            </a>

            <a href="https://github.com/ZehaIrawan" target="_blank">
              <Github className="mr-2" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section1
