import { graphql, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import ProjectItem from './ProjectItem'


const ProjectList = () => {

  const [projectsCount, setProjectsCount] = useState(4)



  const data = useStaticQuery(graphql`
    query MyQuery {
      allDataJson {
        nodes {
          content {
            description
            githublink
            livelink
            projectName
            subtitle
            tags
            screenshot {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  `)

  // console.log(data.allDataJson.nodes[0].content,'List');

  return (
    <div id="portfolio" className="px-6 lg:mx-auto lg:w-4/5">
      {data.allDataJson.nodes[0].content
        .slice(0, projectsCount)
        .map((project, index) => (
          <ProjectItem key={index} index={index} project={project} data={data}/>
        ))}
      {projectsCount < data.allDataJson.nodes[0].content.length && (
        <button
          onClick={() => setProjectsCount(projectsCount + 5)}
          className="block mx-auto border border-theme bg-white w-36 px-4 py-2 mt-6 rounded-lg"
        >
          Load more...
        </button>
      )}
    </div>
  )
}

export default ProjectList
