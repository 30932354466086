import { XIcon } from "@heroicons/react/solid"
import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import ReactDom from "react-dom"
import About from "../components/About"
import ContactForm from "../components/ContactForm"
import Layout from "../components/layout"
import Navbar from "../components/Navbar"
import ProjectList from "../components/ProjectList"
import Section1 from "../components/Section1"
import Seo from "../components/seo"
import TechStack from "../components/TechStack"
import { ProjectProvider } from "../context/ProjectContext"
import Recommendation from "../components/Recommendation"

const IndexPage = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)

  let html

  useEffect(() => (html = document.querySelector("html")))

  useEffect(() => {
    isOpen
      ? (html.style.overflow = "hidden")
      : (html.style.overflow = "visible")
  }, [isOpen])

  const el =
    typeof document !== `undefined` ? document.getElementById("modal") : null

  return (
    <Layout>
      <ProjectProvider>
        <Seo title="Zeha's Portfolio" />

        {el &&
          ReactDom.createPortal(
            isOpen && (
              <div
                className={`backdrop-blur-sm bg-modal_bg h-screen bg-opacity-90 text-white fixed inset-0 z-50 w-full`}
              >
                <div className="pt-12 px-6 text-current right-0">
                  {isOpen && (
                    <XIcon
                      className={`h-6 w-6 top-6 right-6 absolute`}
                      onClick={() => setIsOpen(false)}
                    />
                  )}
                  <ul>
                    <li
                      className="text-3xl mt-4"
                      onClick={() => setIsOpen(false)}
                    >
                      <a href="#portfolio">Portfolio</a>
                    </li>
                    <li
                      className="text-3xl mt-4"
                      onClick={() => setIsOpen(false)}
                    >
                      <a href="#about">About</a>
                    </li>
                    <li
                      className="text-3xl mt-4"
                      onClick={() => setIsOpen(false)}
                    >
                      <a href="#contact">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            ),
            typeof document !== `undefined`
              ? document.getElementById("modal")
              : null
          )}

        <Navbar setIsOpen={setIsOpen} />

        <Section1 />
        <Recommendation />

        <ProjectList />

        <div className="bg-white rounded-tr-5xl mt-16 border border-gray-200">
          <div className="lg:w-4/5 flex flex-col lg:flex-row lg:mx-auto">
            <About />
            <TechStack />
          </div>
        </div>

        <div className="bg-white rounded-tr-5xl lg:mx-auto">
          <ContactForm />
        </div>
      </ProjectProvider>
    </Layout>
  )
}

// export const query = graphql`
//   query ProjectItemQuery {
//     site {
//       siteMetadata {
//         description
//       }
//     }
//   }
// `

export default IndexPage
