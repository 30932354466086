import React from "react"

export const Ruby = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 32 32"
    >
      <defs>
        <linearGradient
          id="a"
          x1="-235.957"
          x2="-235.986"
          y1="-308.579"
          y2="-308.527"
          gradientTransform="matrix(202.935 0 0 -202.78 47910.461 -62541.16)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fb7655"></stop>
          <stop offset="0.41" stopColor="#e42b1e"></stop>
          <stop offset="0.99" stopColor="#900"></stop>
          <stop offset="1" stopColor="#900"></stop>
        </linearGradient>
        <linearGradient
          id="b"
          x1="-235.571"
          x2="-235.697"
          y1="-309.087"
          y2="-309.041"
          gradientTransform="matrix(60.308 0 0 -111.778 14236.351 -34525.395)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#871101"></stop>
          <stop offset="0.99" stopColor="#911209"></stop>
          <stop offset="1" stopColor="#911209"></stop>
        </linearGradient>
        <linearGradient
          id="c"
          x1="-235.896"
          x2="-235.937"
          y1="-313.362"
          y2="-313.129"
          gradientTransform="matrix(188.32 0 0 -21.986 44447.302 -6856.882)"
          xlinkHref="#b"
        ></linearGradient>
        <linearGradient
          id="d"
          x1="-233.515"
          x2="-233.497"
          y1="-309.082"
          y2="-309.161"
          gradientTransform="matrix(65.222 0 0 -97.1 15237.802 -29991.814)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff"></stop>
          <stop offset="0.23" stopColor="#e57252"></stop>
          <stop offset="0.46" stopColor="#de3b20"></stop>
          <stop offset="0.99" stopColor="#a60003"></stop>
          <stop offset="1" stopColor="#a60003"></stop>
        </linearGradient>
        <linearGradient
          id="e"
          x1="-235.3{
      name:'Ruby',

  },14"
          x2="-235.31"
          y1="-309.534"
          y2="-309.607"
          gradientTransform="matrix(105.32 0 0 -106.825 24798.925 -33053.152)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff"></stop>
          <stop offset="0.23" stopColor="#e4714e"></stop>
          <stop offset="0.56" stopColor="#be1a0d"></stop>
          <stop offset="0.99" stopColor="#a80d00"></stop>
          <stop offset="1" stopColor="#a80d00"></stop>
        </linearGradient>
        <linearGradient
          id="f"
          x1="-235.882"
          x2="-235.869"
          y1="-311.851"
          y2="-311.935"
          gradientTransform="matrix(94.321 0 0 -66.418 22271.499 -20707.004)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff"></stop>
          <stop offset="0.18" stopColor="#e46342"></stop>
          <stop offset="0.4" stopColor="#c82410"></stop>
          <stop offset="0.99" stopColor="#a80d00"></stop>
          <stop offset="1" stopColor="#a80d00"></stop>
        </linearGradient>
        <linearGradient
          id="g"
          x1="-235.412"
          x2="-235.333"
          y1="-321.074"
          y2="-320.958"
          gradientTransform="matrix(70.767 0 0 -24.301 16678.116 -7798.647)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff"></stop>
          <stop offset="0.54" stopColor="#c81f11"></stop>
          <stop offset="0.99" stopColor="#bf0905"></stop>
          <stop offset="1" stopColor="#bf0905"></stop>
        </linearGradient>
        <linearGradient
          id="h"
          x1="-223.821"
          x2="-223.796"
          y1="-310.116"
          y2="-310.18"
          gradientTransform="matrix(18.177 0 0 -72.645 4071.017 -22510.233)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff"></stop>
          <stop offset="0.31" stopColor="#de4024"></stop>
          <stop offset="0.99" stopColor="#bf190b"></stop>
          <stop offset="1" stopColor="#bf190b"></stop>
        </linearGradient>
        <linearGradient
          id="i"
          x1="-235.561"
          x2="-235.424"
          y1="-309.258"
          y2="-309.116"
          gradientTransform="matrix(158.162 0 0 -157.937 37256.313 -48819.382)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#bd0012"></stop>
          <stop offset="0.07" stopColor="#fff"></stop>
          <stop offset="0.17" stopColor="#fff"></stop>
          <stop offset="0.27" stopColor="#c82f1c"></stop>
          <stop offset="0.33" stopColor="#820c01"></stop>
          <stop offset="0.46" stopColor="#a31601"></stop>
          <stop offset="0.72" stopColor="#b31301"></stop>
          <stop offset="0.99" stopColor="#e82609"></stop>
          <stop offset="1" stopColor="#e82609"></stop>
        </linearGradient>
        <linearGradient
          id="j"
          x1="-235.424"
          x2="-235.476"
          y1="-309.143"
          y2="-309.126"
          gradientTransform="matrix(127.074 0 0 -97.409 29932.229 -30086.947)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#8c0c01"></stop>
          <stop offset="0.54" stopColor="#990c00"></stop>
          <stop offset="0.99" stopColor="#a80d0e"></stop>
          <stop offset="1" stopColor="#a80d0e"></stop>
        </linearGradient>
        <linearGradient
          id="k"
          x1="-235.839"
          x2="-235.901"
          y1="-309.604"
          y2="-309.555"
          gradientTransform="matrix(94.011 0 0 -105.603 22198.743 -32676.856)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#7e110b"></stop>
          <stop offset="0.99" stopColor="#9e0c00"></stop>
          <stop offset="1" stopColor="#9e0c00"></stop>
        </linearGradient>
        <linearGradient
          id="l"
          x1="-235.854"
          x2="-235.891"
          y1="-311.24"
          y2="-311.202"
          gradientTransform="matrix(79.702 0 0 -81.791 18827.397 -25447.905)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#79130d"></stop>
          <stop offset="0.99" stopColor="#9e120b"></stop>
          <stop offset="1" stopColor="#9e120b"></stop>
        </linearGradient>
        <radialGradient
          id="m"
          cx="-235.882"
          cy="-312.543"
          r="0.076"
          gradientTransform="matrix(93.113 0 0 -48.655 21986.073 -15193.61)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#a80d00"></stop>
          <stop offset="0.99" stopColor="#7e0e08"></stop>
          <stop offset="1" stopColor="#7e0e08"></stop>
        </radialGradient>
        <radialGradient
          id="n"
          cx="-235.282"
          cy="-309.704"
          r="0.097"
          gradientTransform="matrix(97.434 0 0 -75.848 22937.057 -23467.84)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#a30c00"></stop>
          <stop offset="0.99" stopColor="#800e08"></stop>
          <stop offset="1" stopColor="#800e08"></stop>
        </radialGradient>
        <linearGradient
          id="o"
          x1="-231.241"
          x2="-231.299"
          y1="-309.435"
          y2="-309.337"
          gradientTransform="matrix(40.137 0 0 -81.143 9286.998 -25078.589)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#8b2114"></stop>
          <stop offset="0.43" stopColor="#9e100a"></stop>
          <stop offset="0.99" stopColor="#b3100c"></stop>
          <stop offset="1" stopColor="#b3100c"></stop>
        </linearGradient>
        <linearGradient
          id="p"
          x1="-235.898"
          x2="-235.831"
          y1="-317.466"
          y2="-317.537"
          gradientTransform="matrix(78.099 0 0 -32.624 18447.361 -10353.553)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#b31000"></stop>
          <stop offset="0.44" stopColor="#910f08"></stop>
          <stop offset="0.99" stopColor="#791c12"></stop>
          <stop offset="1" stopColor="#791c12"></stop>
        </linearGradient>
      </defs>
      <path
        fill="url(#a)"
        d="M23.693 20.469L7.707 29.961l20.7-1.4L30 7.685z"
      ></path>
      <path fill="url(#b)" d="M28.44 28.542l-1.779-12.279-4.846 6.4z"></path>
      <path fill="url(#c)" d="M28.464 28.542L15.43 27.519l-7.654 2.415z"></path>
      <path fill="url(#d)" d="M7.794 29.937L11.05 19.27 3.885 20.8z"></path>
      <path fill="url(#e)" d="M21.813 22.7l-3-11.735L10.243 19z"></path>
      <path fill="url(#f)" d="M29.32 11.127l-8.1-6.619-2.257 7.3z"></path>
      <path fill="url(#g)" d="M25.53 2.148l-4.767 2.634-3.007-2.67z"></path>
      <path fill="url(#h)" d="M2 24.38l2-3.642L2.382 16.4z"></path>
      <path
        fill="#fff"
        d="M2.274 16.263l1.626 4.61 7.062-1.584 8.062-7.489L21.3 4.569l-3.583-2.53-6.091 2.28C9.706 6.1 5.982 9.635 5.848 9.7s-2.459 4.464-3.574 6.562z"
      ></path>
      <path
        fill="url(#i)"
        d="M7.981 7.981C12.14 3.858 17.5 1.421 19.559 3.5s-.124 7.121-4.283 11.244-9.455 6.69-11.511 4.614.057-7.258 4.216-11.377z"
      ></path>
      <path
        fill="url(#j)"
        d="M7.794 29.933l3.231-10.7 10.729 3.447c-3.879 3.638-8.194 6.713-13.96 7.254z"
      ></path>
      <path
        fill="url(#k)"
        d="M19.038 11.774l2.754 10.91c3.24-3.407 6.149-7.07 7.573-11.6l-10.328.691z"
      ></path>
      <path
        fill="url(#l)"
        d="M29.337 11.139c1.1-3.327 1.357-8.1-3.841-8.985l-4.265 2.355 8.106 6.629z"
      ></path>
      <path
        fill="#9e1209"
        d="M2 24.332c.153 5.49 4.114 5.572 5.8 5.62l-3.9-9.1-1.9 3.48z"
      ></path>
      <path
        fill="url(#m)"
        d="M19.053 11.791c2.49 1.531 7.509 4.6 7.61 4.661a17.552 17.552 0 002.619-5.343l-10.229.683z"
      ></path>
      <path
        fill="url(#n)"
        d="M11.021 19.232l4.319 8.332a27.924 27.924 0 006.385-4.88l-10.7-3.452z"
      ></path>
      <path
        fill="url(#o)"
        d="M3.887 20.861l-.612 7.287c1.155 1.577 2.743 1.714 4.409 1.591-1.205-3-3.614-9-3.8-8.878z"
      ></path>
      <path
        fill="url(#p)"
        d="M21.206 4.528l8.58 1.2c-.458-1.94-1.864-3.192-4.261-3.584l-4.319 2.38z"
      ></path>
    </svg>
  )
}
