import React from "react"

export const GatsbyIcon = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2500 2500" className={className}>
      <path
        fill="#744c9e"
        d="M1250 0C559.6 0 0 559.6 0 1250s559.6 1250 1250 1250 1250-559.6 1250-1250S1940.4 0 1250 0zM268.6 1263l968.4 968.4c-531.8-6.9-961.5-436.6-968.4-968.4zm1201.1 943.9L293.1 1030.3C392.9 594 783.4 268.4 1250 268.4c326.1 0 615.1 159.1 793.6 403.9l-135.9 119.9c-144.8-207.6-385.4-343.5-657.7-343.5-346.7 0-642 220.2-753.6 528.4l1026.5 1026.4c249.3-90.3 441-300.7 505.2-561.3h-425.4V1250h629c-.1 466.6-325.7 857.1-762 956.9z"
      ></path>
    </svg>
  )
}
