import React from 'react'

export const LinkedIn = ({className}) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6444 3.03467C10.1556 3.03467 7.28857 5.86225 7.28857 9.3795V19.3105C7.28857 19.6898 7.59946 20.0002 7.97942 20.0002H11.5718C11.9518 20.0002 12.2627 19.6898 12.2627 19.3105V9.65536C12.2627 8.89674 12.8844 8.27605 13.6444 8.27605C14.4043 8.27605 15.0261 8.89674 15.0261 9.65536V19.3105C15.0261 19.6898 15.3369 20.0002 15.7169 20.0002H19.3093C19.6893 20.0002 20.0001 19.6898 20.0001 19.3105V9.3795C20.0001 5.89674 17.1677 3.03467 13.6444 3.03467Z"
        fill="#505F79"
      />
      <path
        d="M2.48705 0C1.10535 0 0 1.10345 0 2.48276C0 3.86207 1.10535 4.96552 2.48705 4.96552C3.86874 4.96552 4.97409 3.86207 4.97409 2.48276C4.97409 1.10345 3.86874 0 2.48705 0Z"
        fill="#505F79"
      />
      <path
        d="M4.28325 6.27588H0.690846C0.310881 6.27588 0 6.58622 0 6.96553V19.3104C0 19.6897 0.310881 20 0.690846 20H4.28325C4.66321 20 4.97409 19.6897 4.97409 19.3104V6.96553C4.97409 6.58622 4.66321 6.27588 4.28325 6.27588Z"
        fill="#505F79"
      />
    </svg>
  )
}
